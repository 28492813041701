
.filter-rule {
  margin: px2rem(15) 0;
  box-shadow: 0 0 px2rem(5) rgba(#000, 0.1);
  padding: px2rem(10);
}

.tag-inc-excl {
  .badge-danger {
    text-decoration: line-through;
  }
}
