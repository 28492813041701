$root-rem: 16px;

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function px2rem($size) {
  $remSize: $size / strip-unit($root-rem);
  @return $remSize + 0rem;
}

@mixin noUserSelect($select: none) {
  -webkit-touch-callout: $select; /* iOS Safari */
  -webkit-user-select: $select; /* Safari */
  -khtml-user-select: $select; /* Konqueror HTML */
  -moz-user-select: $select; /* Old versions of Firefox */
  -ms-user-select: $select; /* Internet Explorer/Edge */
  user-select: $select;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
